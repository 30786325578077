import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChaseRequestUID } from '@archinsurance-da/arch-portal-tools';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BondData } from '../interfaces/bond.interface';
import { ChaseTransactionFillData } from '../interfaces/chase.interface';
import { PolicyData } from '../interfaces/policy.interface';
import { constants } from './../../@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ChaseService {
  authenticationService: any;

  constructor(private http: HttpClient) {}

  getPaymentTechUID(
    amount: number,
    transType: string,
    tpfd: ChaseTransactionFillData,
    paymentType: string = 'card',
    lob: string = ''
  ): Observable<any> {
    let payload: ChaseRequestUID = {};
    payload.amount = amount;
    payload.sessionId = `sess_${tpfd.id}`;
    payload.orderId = `pp_${tpfd.id}`;

    console.log('transType', transType);
    if (transType === 'auth' || transType === 'auth_only') {
      payload.cssURL = 'https://arch-test-assets.s3.amazonaws.com/arch-chase-insured-portal-update-card.css';
    } else {
      payload.cssURL = 'https://arch-test-assets.s3.amazonaws.com/arch-chase-billing-portal.css';
    }
    console.log('payload.cssURL', payload.cssURL);

    if (lob) {
      payload.lob = lob;
    }

    payload.callbackURL = environment.chaseCallBackUrl;
    payload.name = tpfd.name ? tpfd.name : '';
    payload.transType = transType; //'auth_only' or 'auth_capture';
    console.log('paymentType', paymentType);
    payload.paymentType = paymentType === 'check' ? 'ECP' : 'Credit_Card';
    // console.log('payload.paymentType', payload.paymentType);

    if (payload.paymentType === 'Credit_Card') {
      payload.collectAddress = '3';
      payload.zip = tpfd.postalCode ? tpfd.postalCode : '';
    } else {
      payload.collectAddress = '0';
    }

    if (payload.paymentType === 'ECP') {
      payload.cssURL = 'https://arch-test-assets.s3.amazonaws.com/arch-chase-billing-portal-echeck.css';
    }
    // payload.address = '';
    // payload.city = '';
    // payload.state = '';

    return this.http.post<any>(environment.billingPortalApiHost + constants.initializePaymentEndPoint, payload).pipe(
      map((uidResult) => {
        return uidResult?.uid || uidResult?.uID;
        // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
      })
    );
  }

  updatePolicyPaymentMethod(uid: string, policyData: PolicyData, product: string): Observable<any> {
    product = constants.product_mapping[product];

    let payload = {
      policyEffDate: policyData.policy.policyEffectiveDate,
      policyNo: policyData.policy.polNbr,
      product: product,
      policyId: policyData.policy.policyId,
    };

    //updateCard/:UID
    return this.http.post<any>(environment.billingPortalApiHost + constants.updateCardEndPoint + uid, payload).pipe(
      map((uidResult) => {
        return uidResult;
        // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
      })
    );
  }

  payBond(uid: string, bondData: BondData): Observable<any> {
    //updateCard/:UID
    return this.http.post<any>(environment.billingPortalApiHost + constants.bondPostPayment + uid, bondData).pipe(
      map((uidResult) => {
        return uidResult;
        // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
      })
    );
  }

  // doPostPaymentProcessing(uid: string, policyStore: BillingStore): Observable<any> {

  //   return this.http
  //     .post<any>(environment.billingPortalApiHost + environment.postPaymentEndpoint + `/${uid}`, payload, { headers })
  //     .pipe(
  //       map((uidResult) => {
  //         return uidResult;
  //         // return this.mapMajescoScheduleToPaymentSchedule(scheduleResult);
  //       })
  //     );
  // }
}
