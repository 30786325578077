import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { AbstractControl, FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { AuthenticationService, UserProfile } from '@app/authentication/authentication.service';
import { PolicyService } from '@app/@shared/services/policy.service';
import { BondService } from '@app/@shared/services/bond.service';
import { PolicyIds } from '@app/@shared/interfaces/policy.interface';
import { BondData } from '@app/@shared/interfaces/bond.interface';
import { constants } from '@app/@shared/constants';
import { MaintenanceSchedule, MaintenanceService } from '@archinsurance-da/arch-portal-tools';
import { IconDefinition, faLock, faLockOpen, faBars } from '@fortawesome/free-solid-svg-icons';
//import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  policyId: string;
  public faLock: any = faLock;
  public faLockOpen: any = faLockOpen;
  policies: PolicyIds[];
  bondId: string;
  bonds: BondData[];
  prodmap: any = constants.products;
  prodabbrmap: any = constants.product_mapping;
  fetchCount: number = 0;
  downloading: boolean = false;
  agentCd: string;
  product: string;
  //date: any;
  policyData: any;
  currentRoute: string;
  menuIcon: IconDefinition = faBars;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private policyService: PolicyService,
    private bondService: BondService,
    private maintenanceService: MaintenanceService
  ) {
    route.url.subscribe((data) => {
      if (this.route?.snapshot?.firstChild?.params?.policyid) {
        this.policyId = this.route.snapshot.firstChild.params.policyid;
        if (this.route?.snapshot?.firstChild?.params?.product) {
          this.product = this.route.snapshot.firstChild.params.product;
        }
      } else if (this.route?.snapshot?.firstChild?.params?.bondid) {
        this.bondId = this.route.snapshot.firstChild.params.bondid;
      }
    });
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        route.url.subscribe((data) => {
          if (this.route?.snapshot?.firstChild?.params?.policyid) {
            this.policyId = this.route.snapshot.firstChild.params.policyid;
            if (this.route?.snapshot?.firstChild?.params?.product) {
              this.product = this.route.snapshot.firstChild.params.product;
            }
          }
          this.policyService.getPolicy(this.policyId, this.product).subscribe((policyData) => {
            this.policyData = policyData;
            this.agentCd = this.policyData.responseJSON.agentCd;
          });
        });
      }
    });
  }

  get userProfile(): UserProfile {
    // console.log('this.authenticationService.userProfile', this.authenticationService.userProfile);
    return this.authenticationService.userProfile;
  }
  downloadCoiPdf() {
    if (!this.downloading) {
      this.downloading = true;
      this.policyService
        .getPdfDetails(this.route.snapshot.firstChild.params.policyid, this.product)
        .subscribe((resultBytes) => {
          var blob = new Blob([resultBytes], { type: 'application/pdf' });
          var link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `arch-COI-${this.policyId}.pdf`;
          link.click();

          this.downloading = false;
        });
    }
  }
  dateFormatChg(date: any) {
    let parts = date.split('/');
    date = new Date(parts[0] + '/' + parts[1] + '/' + parts[2]);
    return date.getTime();
  }
  ngOnInit(): void {
    this.setMobile();

    this.policyService.getPolicy(this.policyId, this.product).subscribe((policyData) => {
      this.policyData = policyData;
      this.agentCd = this.policyData.responseJSON.agentCd;
    });
    const startMDate = new Date(constants.maintenanceStartDate);
    const endMDate = new Date(constants.maintenanceEndDate);

    startMDate.toDateString();

    endMDate.toDateString();

    let shedules: MaintenanceSchedule[] = [
      {
        // title: 'This site is temporaily unavailable for maintenance from',
        startDTS: startMDate,
        endDTS: endMDate,
        // description: 'We apologize for the inconvenience. Thank you.',
        warnMinutesBefore: constants.warnMinutesBefore,
      },
    ];

    //Change startDTS and endDTS to show currentMain
    const currentMaintenances = this.maintenanceService.isScheduledMaintenance(shedules, true); //IF second param is false means do not show upcoming
    const currentMaint = currentMaintenances[0];
    if (currentMaint) {
      this.router.navigate(['/maintenance'], { skipLocationChange: true });
    }

    this.policyService.getUserPolicies(this.userProfile.email).subscribe((policies) => {
      // let datePipe = new DatePipe('en-US');
      // this.date = datePipe.transform(new Date(), 'MM/dd/yyyy');
      // this.date = this.dateFormatChg(this.date);
      // policies.sort((pol1: any, pol2: any) => {
      //   return <any>new Date(pol2.policyExpirationDate) - <any>new Date(pol1.policyExpirationDate);
      // });
      //uses logged in user email
      // this.policyService.getUserPolicies('many@dalelarsen.com').subscribe((policies) => {
      //has many
      // this.policyService.getUserPolicies('none@dalelarsen.com').subscribe((policies) => {//has none
      // this.policyService.getUserPolicies('one@dalelarsen.com').subscribe((policies) => {//has one
      // policies.forEach((pol) => {
      //   if (pol['policyExpirationDate'] != null) {
      //     pol['policyExpirationDate'] = this.dateFormatChg(pol['policyExpirationDate']);
      //     //  pol['policyEffectiveDate'] = this.dateFormatChg(pol['policyEffectiveDate']);
      //   }
      // });
      // let removeEffPol = policies;
      // policies.forEach((pol) => {
      //   removeEffPol = policies;
      //   removeEffPol.forEach((e, index1) => {
      //     if (e['policyEffectiveDate'] > this.date) {
      //       policies.splice(index1, 1);
      //     }
      //   });
      // });

      // debugger;
      this.policies = policies;
      //comment out to see just bonds while testing

      // this.policies = policies.map((item: Object) => {
      //   var temp = Object.assign({}, item);

      //   temp['productmap'] = constants.productmap[temp['product']];

      //   return temp;
      // });

      this.fetchCount++;
      if (
        (this.policies?.length == 1 && this.policies[0].policyId && !this.bondId) ||
        (this.policies?.length > 1 && !this.policyId && !this.bondId)
      ) {
        // console.log('Load policy single list');
        this.router.navigateByUrl(
          'dashboard/policy/' + this.prodmap[this.policies[0]['product']] + '/' + this.policies[0].policyId
        );
      }
    });

    this.bondService.getUserBonds(this.userProfile.email).subscribe((bonds) => {
      // this.bondService.getUserBonds('suretytestemail2020@gmail.com').subscribe((bonds) => {//surety test user
      // console.log('bonds', bonds);
      this.bonds = bonds;
      // this.bondService.setBondStore(bonds);
      this.bondService.setBondStores(bonds, true);
      this.fetchCount++;
      if (
        (this.bonds?.length == 1 && this.bonds[0].bondNumber && !this.policyId) ||
        (this.bonds?.length > 1 && !this.policyId && !this.bondId)
      ) {
        // console.log('Load policy single list');
        this.router.navigateByUrl('dashboard/bond/' + this.bonds[0].bondNumber);
      }
    });
  }

  menuOpen: boolean = false;
  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  onChgPolicy(routeStr: any) {
    let cd = routeStr.target.value;
    // this.router.navigatebByUrl(['/'+cd]);
    this.router.navigateByUrl('dashboard/' + cd);
    //this.router.navigate(['/maintenance'], { skipLocationChange: true });
  }

  isMobile: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMobile();
  }

  setMobile() {
    this.isMobile = window.innerWidth < 600;
  }
}
