import packageVersion from '../../package.json';

export const environment = {
  production: true,
  hmr: false,
  version: packageVersion.version + '-int',
  envName: 'int',
  environmentName: 'INT',
  origins: ['https://insured-portal-int.apps.aks-nonprod.archinsurance.com'],
  aipHostPort: 'http://aigi-drvjbss001:8080/',
  billingPortalApiHost: 'https://billing-portal-api-int.apps.aks-nonprod.archinsurance.com/',
  aipHostPortMenu: 'https://aip-authorization-ms-int.apps.aks-nonprod.archinsurance.com/',
  topmenuEndPoint: 'aip-authorization-ms/authorize/menuset/menu',
  chaseOrigin: 'https://www.chasepaymentechhostedpay-var.com',
  chaseCallBackUrl: 'https://insured-portal-int.apps.aks-nonprod.archinsurance.com/',
  aipHostPort1: 'https://aip-base-api-int.apps.aks-nonprod.archinsurance.com/aip-base-api/aip-base-api',
  aipHostPort2: 'https://aip-authorization-ms-int.apps.aks-nonprod.archinsurance.com',
  aipHostPortAdmin: 'https://aip-admin-api-int.apps.aks-nonprod.archinsurance.com',
  appUrl: 'https://aip-base-int.apps.aks-nonprod.archinsurance.com/',
  aipAdminRedirectUrl: 'https://aip-admin-int.apps.aks-nonprod.archinsurance.com',
  getSignature: '/aip-admin-api/aip-admin-api/getUserSignature',
  updateSignature: '/aip-admin-api/aip-admin-api/saveUserSignature',
  serviceEndPortals: '/aip-admin-api/aip-admin-api/getPortals',
  serviceEndPointGetRolesAgenciesAndProducts: '/aip-admin-api/aip-admin-api/getRolesAgenciesAndProducts',
  serviceEndPointUserDetail: '/aip-base-api/getUserdetails/',

  internalUserFlag: true,
  externalUserFlag: true,
  showDebug: true,
  AIP_USER_ROLE: 'AIGI-ROLE-DIGAIP-USER',
  AIP_ARCH_ROLE: 'AIGI-ROLE-DIGAIP-ARCH',
  AIP_AGENCY_ROLE: 'AIGI-ROLE-DIGAIP-AGENCY',
  AIP_ADMIN_ROLE: 'AIGI-ROLE-DIGAIP-ADMIN',
  CAS_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-CAS-ARCH',
  CAS_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-CAS-AGENCY',
  CAS_ENV_ROLE: 'AIGI-ROLE-OBCAS-USER',
  DIGDA_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-DIGDA-ARCH',
  DIGDA_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-DIGDA-AGENCY',
  DIGDA_ENV_ROLE: 'AIGI-ROLE-DIGDA-USER',
  FPL_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-OBFPL-ARCH',
  FPL_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-OBFPL-AGENCY',
  FPL_ENV_ROLE: 'AIGI-ROLE-OBFPL-USER',
  CLAIMS_FUNCTIONAL_ROLE_USER: 'AIGI-ROLE-CLAIMS-USER',
  CLAIMS_FUNCTIONAL_ROLE_ADMIN: 'AIGI-ROLE-CLAIMS-ADMIN',
  CLAIMS_ENV_ROLE: 'AIGI-ROLE-CLAIMS-USER',
  SURETY_FUNCTIONAL_ROLE_UW: 'AIGI-ROLE-SURETY-UNDERWRITER',
  SURETY_FUNCTIONAL_ROLE_AGENT: 'AIGI-ROLE-SURETY-AGENT',
  SURETY_ENV_ROLE: 'AIGI-ROLE-SURETY-USER',
  SYNC_WITH_PL_PORTAL: 'pl-portal-int',
  SYNC_WITH_UW_PORTAL: 'uw-portal-int',
  SYNC_WITH_AIP_BASE_PORTAL: 'aip-base-portal-int',
  SYNC_WITH_PAYMENT_PORTAL: 'payment-portal-int',
  ARCH: 'ARCH',
  AGENCY: 'AGENCY',
  AGENCY_ROLE: 'AIGI-ROLE-AGENCY-UNDERWRITER',
  ARCH_ROLE: 'AIGI-ROLE-ARCH-UNDERWRITER',
  internal: 'ARCH',
  external: 'AGENCY',
  internalUser: 'Internal',
  externalUser: 'External',
  INTERNAL_EMAIL_DOMAIN: [
    'archinsurance.com',
    'archgroup.com',
    'archcapservices.com',
    'archgso.com',
    'corp.archcapservices.com',
  ],
  SKIP_PORTALS_INTERNAL_USER: ['AIGI-BasePortal'],
  AUTO_PORTALS_INTERNAL_USER: [{ 'AIGI-BasePortal': 'AIGI-ROLE-DIGAIP-ARCH-UNDERWRITER' }],
  AUTO_PORTALS_EXTERNAL_USER: [{ 'AIGI-BasePortal': 'AIGI-ROLE-DIGAIP-AGENCY-UNDERWRITER' }],
};
