export const constants = {
  bookOfBusinessEndPoint: 'aipdashboardms/getYtdMtdCurrentBookOfBusiness',
  currentBoBForFromToDateEndPoint: 'aipdashboardms/getCurrentBoBForFromToDate',
  actionItemsEndPoint: 'aipdashboardms/getActionItems',
  permissionEndPoint: 'aipauth0api/getRolesAndPortals',
  bopQuoteEndPoint: 'api/quote',
  paymentScheduleEndpoint: 'billing-portal-api/paymentSchedule',
  initializePaymentEndPoint: 'billing-portal-api/initializePayment',
  paymentQueryEndpoint: 'billing-portal-api/paymentQuery',
  updateCardEndPoint: 'billing-portal-api/updateCard/',
  getPolicyEndPoint: 'billing-portal-api/getPolicy',
  bindEndpoint: 'billing-portal-api/bind',
  policiesEndpoint: 'billing-portal-api/policies/',
  bondPostPayment: 'billing-portal-api/bond/postPayment/',
  getBondsByEmail: 'billing-portal-api/bonds/',
  billingInquiry: 'billing-portal-api/billingInquiry/',
  printPolicyEndpoint: 'billing-portal-api/issueletter',
  getPortals: '/aip-admin-api/getPortals/',
  //getSignature: '/aip-admin-api/getUserSignature',
  //updateSignature: '/aip-admin-api/saveUserSignature',
  //serviceEndPortals: '/aip-admin-api/getPortals',
  // serviceEndPointGetRolesAgenciesAndProducts: '/aip-admin-api/getRolesAgenciesAndProducts',
  //serviceEndPointUserDetail: '/getUserdetails/',

  serviceEndPointaddUser: '/aip-admin-api/addUser',
  adminPortalEndpoint: '/admin',
  serviceEndPointsContentServer: 'audit/resourcePath?appName=',
  userClaims: 'aipsecurityapi/loginUnregisteredUserClaims',
  products: { canopy: 'ea', essentialpl: 'pl' },
  product_mapping: {
    ea: 'canopy',
    pl: 'essentialpl',
    canopy: 'canopy',
    essentialpl: 'essentialpl',
  },
  lob: 'professioanlliability',
  getCOIDetails: 'billing-portal-api/getCertificateOfInsurancePDF/',
  maintenanceStartDate: '07/15/2022 22:00:00 GMT-0400 (Eastern Daylight Time)',
  maintenanceEndDate: '07/16/2022 02:00:00 GMT-0400 (Eastern Daylight Time)',
  warnMinutesBefore: 5760,
};
