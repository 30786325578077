import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BondData } from '../interfaces/bond.interface';
import { PolicyData, PolicyIds } from '../interfaces/policy.interface';
import { constants } from './../../@shared/constants';

@Injectable({
  providedIn: 'root',
})
export class BondService {
  constructor(private http: HttpClient) {}

  // bondPostPayment: 'billing-portal-api/bond/postPayment/',//UID
  // getBondsByEmail: 'billing-portal-api/bonds/',//suretytestemail2020@gmail.com

  getUserBonds(email: string): Observable<BondData[]> {
    return this.http.get<any>(environment.billingPortalApiHost + constants.getBondsByEmail + email).pipe(
      map((bonds: any[]) => {
        return bonds;
      })
    );
  }

  bondPostPayment(UID: string): Observable<any> {
    return this.http.get<any>(environment.billingPortalApiHost + constants.bondPostPayment + UID).pipe(
      map((data: any) => {
        return data;
      })
    );
  }

  private readonly _bondStoreSource = new BehaviorSubject<BondData[]>([]);
  private readonly _storageName: string = 'bondStore';

  // Exposed observable (read-only).
  readonly bondStores$ = this._bondStoreSource.asObservable();

  // Get last value without subscribing to the billingStores$ observable (synchronously).
  getBondStores(): BondData[] {
    return this._bondStoreSource.getValue();
  }

  getBondStoreFromStorage() {
    this.setBondStores(JSON.parse(sessionStorage.getItem(this._storageName)));
  }

  private _persistBondStores(billingStore: BondData[]): void {
    sessionStorage.setItem(this._storageName, JSON.stringify(billingStore));
  }

  setBondStores(bondStore: BondData[], persist: boolean = false): void {
    bondStore = JSON.parse(JSON.stringify(bondStore)); //copy so that we do not mutate the original
    this._bondStoreSource.next(bondStore);
    if (persist) this._persistBondStores(bondStore);
  }

  getBondFromStoreById(bondId: string) {
    const bondStores = this.getBondStores();
    // console.log('bondStores', bondStores);
    return bondStores.find((bond) => {
      return bond.bondNumber == bondId;
    });
  }
}
