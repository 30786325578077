import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { Logger } from '@core/logger.service';

declare let pendo: any;

export interface UserProfile {
  given_name: string;
  family_name: string;
  nickname: string;
  name: string;
  email: string;
  email_verified: boolean;
  groups: string[];
  roles: string[];
  phone?: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private log = new Logger('AuthenticationService');

  private authenticatedSubject = new BehaviorSubject<boolean>(false);
  private loadedSubject = new ReplaySubject<boolean>();
  private _loaded = this.loadedSubject.asObservable();
  private _authenticated = this.authenticatedSubject.asObservable();

  private _userProfile: UserProfile;
  private pendoInitialized = false;

  constructor(private oauthService: OAuthService, private router: Router, private ngZone: NgZone) {
    this.oauthService.events.subscribe((event) => {
      this.authenticatedSubject.next(this.oauthService.hasValidAccessToken());

      if (event instanceof OAuthErrorEvent) {
        this.log.error(event);
      } else {
        this.log.info(event);
      }
    });

    this.oauthService.events.pipe(filter((event) => ['token_received'].includes(event.type))).subscribe((e) => {
      this.loadUserProfile();
    });

    this.oauthService.events
      .pipe(filter((event) => ['session_terminated', 'session_error'].includes(event.type)))
      .subscribe((event) => this.login());

    window.addEventListener('storage', (event) => {
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      this.log.warn('Noticed changes to access_token (most likely from another tab), updating auth state');

      const authenticated = this.oauthService.hasValidAccessToken();
      this.authenticatedSubject.next(authenticated);

      if (!authenticated) {
        this.login();
      }
    });

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public login(targetUrl?: string) {
    this.oauthService.initLoginFlow(encodeURIComponent(targetUrl || this.router.url));
  }

  public checkRoles(): boolean {
    // console.log('this._userProfile.roles', this._userProfile.roles);
    return (
      //AIGI-ROLE-INSUREDPORTAL-INSURED will be deprecated in a future release, for now supporting old and new
      (this._userProfile.roles.includes('AIGI-ROLE-INSURED') ||
        this._userProfile.roles.includes('AIGI-ROLE-INSUREDPORTAL-INSURED')) &&
      this._userProfile.roles.includes(`AIGI-ROLE-INSUREDPORTAL-USER-${environment.envName.toUpperCase()}`)
    );
  }

  public runInitialLoginSequence(): Promise<void> {
    return this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        } else {
          this.login();
        }

        return Promise.reject();
      })
      .then(() => {
        this.loadedSubject.next(true);
        this.loadUserProfile();
        const state = decodeURIComponent(decodeURIComponent(this.oauthService.state)); //auth0 double encodes URIs
        if (!this.checkRoles()) {
          this.router.navigate(['access-denied']);
        } else if (state && state !== 'undefined' && state !== 'null') {
          this.log.debug(`There was state, so we're sending you to: ${state}`);
          this.router.navigateByUrl(state);
        } else if (this.router.url === '/access-denied') {
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            //this fixes route not reloading after change route below...
            return false;
          };
          setTimeout(() => {
            this.router.navigated = false;
            this.router.navigate(['']); //if has role access and is on the access denied page, forward to landing page
          }, 200);
        }
      })
      .catch(() => this.loadedSubject.next(true));
  }

  public logout() {
    this.oauthService.logOut();
  }

  get authenticated(): Observable<boolean> {
    return this._authenticated;
  }

  get loaded(): Observable<boolean> {
    return this._loaded;
  }

  get userProfile(): UserProfile {
    return this._userProfile;
  }

  private loadUserProfile() {
    this._userProfile = this.oauthService.getIdentityClaims() as UserProfile;
    this._userProfile.groups = this.oauthService.getIdentityClaims()['http://www.archinsurance.com/claims/groups'];
    this._userProfile.roles = this.userProfile['http://www.archinsurance.com/claims/roles'];

    // Only initialize pendo once
    if (!this.pendoInitialized) {
      this.pendoInitialized = true;
      this.initializePendo(this._userProfile);
    }
  }
  public isUserInternal(): boolean {
    let emailId = this._userProfile.email;
    var domain = emailId.substring(emailId.lastIndexOf('@') + 1);
    return environment.INTERNAL_EMAIL_DOMAIN.some((i: any) => i.includes(domain));
  }
  private initializePendo(userInfo: UserProfile) {
    this.ngZone.runOutsideAngular(() => {
      const emailId = !!userInfo.email ? userInfo.email : 'EMAIL-NOT-FOUND';
      const fullName = !!userInfo.name ? userInfo.name : 'NAME-NOT-FOUND';
      const role = !!userInfo['http://www.archinsurance.com/claims/roles']
        ? userInfo['http://www.archinsurance.com/claims/roles'].toString()
        : null;

      pendo.initialize({
        visitor: {
          id: emailId, // Required if user is logged in
          email: emailId, // Recommended if using Pendo Feedback, or NPS Email
          full_name: fullName, // Recommended if using Pendo Feedback
          role,
        },

        account: {
          id: 'ACCOUNT-UNIQUE-ID', // Highly recommended
        },
      });
    });
  }
}
