<div *ngIf="!bondReady">
  <apt-state-block state="info" title="Loading bond..."></apt-state-block>
</div>

<div fxLayout="column" *ngIf="bondReady" fxFill>
  <div class="apt-card-grid" fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="20px">
    <apt-card class="test-card" fxFlex [showFooter]="false">
      <!--  [footerAction]="downloadBond()" [showFooter]="bondData?.bondStatus === 'Active'" -->

      <ng-container header> Bond Status: #{{ bondId }} </ng-container>

      <ng-container body>
        <div fxLayout="column">
          <div
            fxFlex
            [ngClass]="{
              'bond-pending': bondData?.bondStatus !== 'Active',
              'bond-active': bondData?.bondStatus === 'Active'
            }"
            fxLayout="row"
          >
            <div fxFlex="50px">
              <ng-container *ngIf="bondData?.bondStatus !== 'Active'">
                <fa-icon [icon]="pendingIcon"></fa-icon>
              </ng-container>
              <ng-container *ngIf="bondData?.bondStatus === 'Active'">
                <fa-icon [icon]="activeIcon"></fa-icon>
              </ng-container>
            </div>

            <div class="bond-status" fxFlex fxLayout="column" fxLayoutAlign="center start">
              <div fxFlex class="bond-status-label" fxLayout="column" fxLayoutAlign="center start">Status:</div>
              <div fxFlex style="font-weight: 600" fxLayout="column" fxLayoutAlign="center start">
                {{ bondData?.bondStatus === 'Pending' ? 'Pending Payment' : 'Paid' }}
              </div>
            </div>

            <div
              class="bond-status"
              fxFlex
              fxLayout="column"
              fxLayoutAlign="center start"
              *ngIf="bondData?.bondStatus === 'Pending'"
            >
              <div fxFlex class="bond-status-label" fxLayout="column" fxLayoutAlign="center start">Payment Due:</div>
              <div fxFlex style="font-weight: 600" fxLayout="column" fxLayoutAlign="center start">
                {{ bondData?.premium | currency }}
              </div>
            </div>

            <div
              *ngIf="bondData?.bondStatus === 'Pending' && !paymentType"
              fxFlex="50"
              fxLayout="row"
              fxLayoutAlign="end center"
              fxLayoutGap="8px"
            >
              <apt-button [primary]="true" (click)="paymentType = 'check'" [icon]="faUniversity"
                >Pay With Bank Account
              </apt-button>
              <apt-button [primary]="true" (click)="paymentType = 'card'" [icon]="faCreditCard"
                >Pay With Card
              </apt-button>
            </div>

            <div *ngIf="paymentType" fxFlex="50" fxLayout="row" fxLayoutAlign="end start" fxLayoutGap="8px">
              <div>
                Selected Payment Type:
                <span style="font-weight: 600">{{ paymentType == 'check' ? 'Bank Account' : 'Credit Card' }}</span>
              </div>
            </div>
          </div>

          <div *ngIf="paymentType" fxFlex fxLayout="column" class="payment-confirm-form">
            <form [formGroup]="bondForm" (ngSubmit)="payBond()">
              <p style="font-weight: 600">
                Before proceeding to payment, please review and confirm the required fields below:
              </p>

              <div style="margin-top: 8px">
                <apt-checkbox
                  label="I understand that a third party payment processor (the “Payment Processor”) processes the payments for this Bond.  Arch’s current Payment Processor is Chase Paymentech, and I understand that my payments will be processed by Chase.  I understand and agree that Arch Insurance Group Inc. does not control and is not liable for the security or performance of the Payment Processor. By making use of this payment portal, I agree to be bound by the applicable Payment Processor terms and conditions and privacy policy. The applicable policy may be found at <a href='https://merchantservices.chase.com/online-privacy-policy' target='_blank'>this link</a>."
                >
                  <input type="checkbox" name="legal" formControlName="legal" required />
                </apt-checkbox>
              </div>

              <!-- <div style="margin-top: 8px">
                <apt-checkbox
                  label="By making use of this payment portal, I agree to be bound by the applicable Payment Processor terms and conditions and privacy policy. The applicable policy may be found at <a href='https://merchantservices.chase.com/online-privacy-policy' target='_blank'>this link</a>."
                >
                  <input type="checkbox" name="legal2" formControlName="legal2" required />
                </apt-checkbox>
              </div> -->

              <div style="margin-top: 8px">
                <apt-checkbox
                  label="I represent and warrant that the information provided is accurate and I am authorized to use the payment method provided."
                >
                  <input type="checkbox" name="legal2" formControlName="legal2" required />
                </apt-checkbox>
              </div>

              <apt-button-footer>
                <ng-container buttonFooterLeft>
                  <apt-button secondary="default" (click)="cancelPaymentType()"> Cancel </apt-button>
                </ng-container>

                <ng-container buttonFooterRight>
                  <apt-button
                    [primary]="true"
                    type="submit"
                    [disabled]="!bondForm.valid"
                    [title]="bondForm.valid ? '' : 'Please agree to the required options.'"
                    >Proceed to Payment
                  </apt-button>
                </ng-container>
              </apt-button-footer>
            </form>
          </div>
        </div>
      </ng-container>
      <div footer>{{ downloading ? 'Downloading...' : 'Download Bond' }}</div>
    </apt-card>

    <!--  -->
    <!--
    <apt-card class="test-card" fxFlex [footerAction]="payBond('card')">
      <ng-container header>Pay For Bond</ng-container>
      <ng-container body>

      </ng-container>
      <ng-container footer>Pay Now</ng-container>
    </apt-card> -->
  </div>

  <div fxFlex class="bond-info">
    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
        <apt-h2 underline>Principal Information</apt-h2>

        <div fxLayout="row" class="info-row">
          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Insured Name">
              <apt-text-waiter [text]="bondData?.principalName"></apt-text-waiter>
            </apt-text-label>
          </div>
        </div>

        <div fxLayout="row" class="info-row">
          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Address">
              <apt-text-waiter [text]="addressString"></apt-text-waiter>
            </apt-text-label>
          </div>
        </div>
      </div>

      <div fxFlex fxLayout="column" fxLayoutAlign="start stretch">
        <apt-h2 underline>Contact Information</apt-h2>

        <div fxLayout="row" class="info-row">
          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Name">
              <apt-text-waiter [text]="userProfile.given_name + ' ' + userProfile.family_name"></apt-text-waiter>
            </apt-text-label>
          </div>
        </div>

        <div fxLayout="row" class="info-row">
          <div fxFlex fxLayout="column" fxLayoutAlign="center start">
            <apt-text-label label="Email Address">
              <apt-text-waiter [text]="userProfile.email"></apt-text-waiter>
            </apt-text-label>
          </div>
        </div>
      </div>
    </div>

    <apt-h2 underline>Bond Details</apt-h2>

    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Description">
          <apt-text-waiter [text]="bondData?.bondDescription"> </apt-text-waiter>
        </apt-text-label>
      </div>
    </div>

    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Effective Date">
          <apt-text-waiter [text]="bondDates.effectiveDate | date: 'MM/dd/yyyy'"></apt-text-waiter>
        </apt-text-label>
      </div>

      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Expiration Date">
          <apt-text-waiter [text]="bondDates.expirationDate | date: 'MM/dd/yyyy'"></apt-text-waiter>
        </apt-text-label>
      </div>
    </div>

    <div fxLayout="row" class="info-row">
      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Bond Amount">
          <apt-text-waiter [text]="bondData?.bondAmount | currency"></apt-text-waiter>
        </apt-text-label>
      </div>

      <div fxFlex fxLayout="column" fxLayoutAlign="center start">
        <apt-text-label label="Premium">
          <apt-text-waiter [text]="bondData?.premium | currency"></apt-text-waiter>
        </apt-text-label>
      </div>
    </div>
  </div>
</div>
