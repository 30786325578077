import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BondData } from '@app/@shared/interfaces/bond.interface';
import { BondService } from '@app/@shared/services/bond.service';
import { ChaseService } from '@app/@shared/services/chase.service';
import { PolicyService } from '@app/@shared/services/policy.service';
import { ChaseError } from '@archinsurance-da/arch-portal-tools';
import { environment } from '@env/environment';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-update-card',
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.scss'],
})
export class UpdateCardComponent implements OnInit {
  type: string;
  subjectId: string;
  chaseUID: string;
  // chaseUID: string = '6BB5D4B4F9E047C9AF36041E3894FD1A';
  processing: boolean;
  errors: ChaseError[] = [];
  policyData: any; //PolicyData;
  bondData: BondData;
  cardUpdated: boolean = false;
  paymentSuccess: boolean = false;
  paymentType: string;
  env = environment.envName === 'prod' ? 'prod' : 'stage';
  private product: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private chaseService: ChaseService,
    private policyService: PolicyService,
    private bondService: BondService
  ) {
    if (environment.envName !== 'prod') {
      //in testing env chase sends alerts manually, override alerts from chase on sandbox
      window.alert = function () {};
    }
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((routeParams) => {
      // const routeParams = this.route.snapshot.paramMap;
      this.type = routeParams.get('type');
      this.subjectId = routeParams.get('id');
      this.paymentType = routeParams.get('paymentType');
      this.product = routeParams.get('product');

      const updateCard = this.paymentType.startsWith('update');
      let payMethod = this.paymentType;
      if (updateCard) {
        payMethod = this.paymentType.replace('update-', '');
      }

      console.log('this.type', this.type);
      console.log('this.subjectId', this.subjectId);

      if (this.type == 'policy') {
        this.policyService.getPolicy(this.subjectId, this.product).subscribe((policyData) => {
          this.policyData = policyData;
          if (!this.chaseUID) {
            this.chaseService
              .getPaymentTechUID(
                0.01,
                'auth_only',
                {
                  // name: policyData.policy.addressDto.insuredName,
                  // postalCode: policyData.policy.addressDto.insuredAddressPostalCd,
                  // id: policyData.policy.policyId,
                  name: this.policyData.policy.addressDto.insuredName,
                  postalCode: this.policyData.policy.addressDto.insuredAddressPostalCd,
                  id: this.policyData.policy.policyId,
                },
                payMethod
              )
              .subscribe((uid) => {
                this.chaseUID = uid;
              });
          }
        });
      } else if (this.type == 'bond') {
        //get bond
        // const bond = this.bondService.getBondFromStoreById(this.subjectId);
        this.bondService.getBondStoreFromStorage();
        if (!this.chaseUID) {
          this.bondService.bondStores$.pipe(first()).subscribe(() => {
            //wait for bondStore to be ready
            const bondData = this.bondService.getBondFromStoreById(this.subjectId);
            this.bondData = bondData;

            this.chaseService
              .getPaymentTechUID(
                bondData.premium,
                'auth_capture',
                {
                  id: bondData.bondNumber,
                  name: bondData.principalName,
                  postalCode: bondData.principalAddressZip,
                },
                payMethod,
                'surety'
              )
              .subscribe((uid) => {
                this.chaseUID = uid;
              });
          });
        }
      }
    });
  }

  onSuccess(data: any) {
    console.log('chase success data', data);
    this.processing = true;
    if (data.content.uID) {
      if (this.type == 'bond') {
        this.chaseService.payBond(data.content.uID, this.bondData).subscribe((response) => {
          // console.log('response', response);
          this.processing = false;

          if (response.chaseSuccessCode === '000' && response.returnCode === 'SUCCESS') {
            this.paymentSuccess = true;

            // } else if (response.chaseErrorCode) {
            //   this.errors = this.payerInfoService.parseChaseErrors([response.chaseErrorCode]);
          } else if (response.returnMessage) {
            this.errors.push({
              code: 'NA',
              message: response.returnMessage.replace(/<br\s*[\/]?>/gi, ' '),
            });
          } else {
            this.errors.push({
              code: 'NA',
              message: 'There was an unexpected error. Please contact Arch Service Center for support.',
            });
          }
        });
      } else if (this.paymentType.startsWith('update') || (this.type != 'bond' && this.paymentType == 'check')) {
        this.chaseService
          .updatePolicyPaymentMethod(data.content.uID, this.policyData, this.product)
          .subscribe((response) => {
            // console.log('response', response);
            this.processing = false;

            if (response.chaseSuccessCode === '000' && response.returnCode === 'SUCCESS') {
              this.cardUpdated = true;

              // } else if (response.chaseErrorCode) {
              //   this.errors = this.payerInfoService.parseChaseErrors([response.chaseErrorCode]);
            } else if (response.returnMessage) {
              this.errors.push({
                code: 'NA',
                message: response.returnMessage.replace(/<br\s*[\/]?>/gi, ' '),
              });
            } else {
              this.errors.push({
                code: 'NA',
                message: 'There was an unexpected error. Please contact Arch Service Center for support.',
              });
            }
          });
      }
    }
  }

  backToPrev() {
    this.router.navigate(['dashboard', this.type, this.subjectId]);
  }

  onCancel(data: any) {
    console.log('chase cancel data', data);
    this.backToPrev();
  }
  onError(errors: ChaseError[]) {
    // console.log('chase errors', errors);
    this.errors = errors;
    this.processing = false;
  }
  onValidationStart() {
    this.processing = true;
  }
  isMobile: boolean = false;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setMobile();
  }

  setMobile() {
    this.isMobile = window.innerWidth < 600;
  }
}
