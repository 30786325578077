import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { HomeComponent } from './home.component';
import { Shell } from '@app/shell/shell.service';
import { AccessDeniedComponent } from '@app/@shared';
import { PolicyComponent } from './policy/policy.component';
import { BondComponent } from './bond/bond.component';
import { UpdateCardComponent } from './update-card/update-card.component';
import { MaintenanceComponent } from '@app/@shared/maintenance/maintenance.component';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
      path: 'dashboard',
      component: HomeComponent,
      children: [
        {
          path: 'policy/:product/:policyid',
          component: PolicyComponent,
        },
        {
          path: 'bond/:bondid',
          component: BondComponent,
        },
      ],
    },
    {
      path: 'payment/:paymentType/:type/:product/:id', //:paymentType can be update-card, update-check, check, or card. :type is policy or bond, :id is the policy or bond id.
      component: UpdateCardComponent,
    },
    { path: 'access-denied', component: AccessDeniedComponent },
    { path: 'maintenance', component: MaintenanceComponent },
  ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class HomeRoutingModule {}
