import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, UserProfile } from '@app/authentication/authentication.service';
import { BondData } from '@app/@shared/interfaces/bond.interface';
import { BondService } from '@app/@shared/services/bond.service';
import {
  IconDefinition,
  faExclamationCircle,
  faCheckCircle,
  faUniversity,
  faCreditCard,
} from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { Validators, UntypedFormBuilder } from '@angular/forms';

interface BondStore {
  [bondId: string]: BondData;
}

@Component({
  selector: 'app-bond',
  templateUrl: './bond.component.html',
  styleUrls: ['./bond.component.scss'],
})
export class BondComponent implements OnInit {
  bondId: string;
  bondDataArr: BondData[];
  bondData: BondData;
  bondDates: any;
  bondStore: BondStore = {};
  addressString: string;
  downloading: boolean = false;
  bondReady: boolean = false;
  pendingIcon: IconDefinition = faExclamationCircle;
  activeIcon: IconDefinition = faCheckCircle;
  faUniversity: IconDefinition = faUniversity;
  faCreditCard: IconDefinition = faCreditCard;
  paymentType: string;

  bondForm = this.fb.group({
    legal: ['', Validators.required],
    legal2: ['', Validators.required],
    // legal3: ['', Validators.required],
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private bondService: BondService,
    private fb: UntypedFormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.route.paramMap.subscribe((routeParams) => {
      // const routeParams = this.route.snapshot.paramMap;
      this.bondId = routeParams.get('bondid');
      console.log('this.bondId', this.bondId);
      // if (this.bondSub$){
      //   this.bondSub$.unsubscribe();
      // }
      this.setBondData();
    });

    this.bondService.bondStores$.subscribe(() => {
      this.setBondData();
    });
  }

  setBondData() {
    //wait for bondStore to be ready
    this.bondData = this.bondService.getBondFromStoreById(this.bondId);
    // console.log('this.bondData', this.bondData);
    if (this.bondData) {
      this.setGeneratedData();
      this.bondReady = true;
    }
  }

  ngOnInit(): void {}

  get userProfile(): UserProfile {
    // console.log('this.authenticationService.userProfile', this.authenticationService.userProfile);
    return this.authenticationService.userProfile;
  }

  setGeneratedData() {
    let bondEffDate = new Date(this.bondData.bondEffectiveDate);
    let bondExpDate = new Date(this.bondData.bondExpirationDate);
    this.bondDates = {
      effectiveDate: bondEffDate.toISOString().split('T').shift(),
      expirationDate: bondExpDate.toISOString().split('T').shift(),
    };
    // const addressDto = this.bondData.addressDto;
    this.addressString = `${this.bondData.principalAddressStreet}, ${this.bondData.principalAddressCity}, ${this.bondData.principalAddressState} ${this.bondData.principalAddressZip}`;
  }

  cancelPaymentType() {
    this.paymentType = undefined;
    this.bondForm.controls['legal'].setValue(false);
    this.bondForm.controls['legal2'].setValue(false);
    // this.bondForm.controls['legal3'].setValue(false);
  }

  downloadBond() {
    return () => {
      if (!this.downloading) {
        this.downloading = true;
        // this.bondService.downloadBond(this.bondData).subscribe((resultBytes) => {
        //   var blob = new Blob([resultBytes], { type: 'application/pdf' });
        //   var link = document.createElement('a');
        //   link.href = window.URL.createObjectURL(blob);
        //   link.download = `arch-bond-${this.bondData.bond.bondId}.pdf`;
        //   link.click();

        //   this.downloading = false;
        // });

        setTimeout(() => {
          this.downloading = false;
        }, 2000);
      }
    };
  }

  payBond() {
    if (this.paymentType) {
      //:paymentType can be update-card, update-check, check, or card. :type is policy or bond, :id is the policy or bond id.
      this.router.navigate([`payment/${this.paymentType}/bond/${this.bondId}`]);
    }
  }
}
