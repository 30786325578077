import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import {
  AptButtonModule,
  AptFormModule,
  AptLayoutModule,
  AptTypographyModule,
  AptTemplatesModule,
  AptDialogModule,
  AptIframeModule,
  AptTableModule,
  AptLoadersModule,
  AptTextLabelModule,
  AptInformModule,
  AptNavigationModule,
  AptCardsModule,
  AptStateBlockModule,
  AptChaseIframeModule,
} from '@archinsurance-da/arch-portal-tools';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PolicyComponent } from './policy/policy.component';
import { BondComponent } from './bond/bond.component';
import { RouterModule } from '@angular/router';
import { UpdateCardComponent } from './update-card/update-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FlexLayoutModule,
    AptButtonModule,
    AptFormModule,
    AptLayoutModule,
    AptLoadersModule,
    AptTextLabelModule,
    AptTypographyModule,
    AptTemplatesModule,
    AptDialogModule,
    AptIframeModule,
    AptChaseIframeModule,
    AptTableModule,
    AptInformModule,
    AptNavigationModule,
    AptCardsModule,
    AptStateBlockModule,
    HomeRoutingModule,
  ],
  declarations: [HomeComponent, PolicyComponent, BondComponent, UpdateCardComponent],
})
export class HomeModule {}
