import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, UserProfile } from '@app/authentication/authentication.service';
import { MenuItem, RolesAndPortals } from '@archinsurance-da/arch-portal-tools';
import { environment } from '@env/environment';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;
  menuItems: MenuItem[];
  settingsItems: MenuItem[] = [
    // {
    //  // text: 'My Profile',
    //   //routerLink: ['/profile'],
    //  // action: () => {},
    // },
  ];
  rolesAndPortals: RolesAndPortals = {
    defaultPortal: {
      //our vertical portal does not exist in AIP, so manually adding it
      'Insured Portal': 'http://localhost:3333',
    },
    portals: [],
  };

  envName: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private headerService: HeaderService
  ) {}

  ngOnInit() {
    this.envName = environment.environmentName;
    this.headerService.getMenu().subscribe(
      (data: any) => {
        data.forEach((menu: any) => {
          if (new RegExp(/https?:\/\//).test(menu.url)) {
            Object.defineProperty(menu, 'action', {
              value: () => this.menuClick(menu.url),
            });
          } else {
            //if not we assume it is a slug that the angular router will handle

            Object.defineProperty(menu, 'routerLink', {
              value: menu.url,
            });
          }
        });
        this.menuItems = data;
      },
      (dataError: any) => {
        console.log('warn', dataError);
      }
    );
  }

  menuClick(urlSlug: any) {
    window.open(urlSlug, '_blank');
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }

  logout() {
    this.authenticationService.logout();
  }
  doLogout() {
    return () => {
      this.authenticationService.logout();
    };
  }

  get userProfile(): UserProfile {
    return this.authenticationService.userProfile;
  }
}
