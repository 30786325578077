import packageVersion from '../../package.json';

export const environment = {
  production: true,
  hmr: false,
  version: packageVersion.version,
  envName: 'prod',
  environmentName: 'PROD',
  origins: ['https://insured.archinsurance.com', 'https://insured-portal.apps.aks.archinsurance.com'],
  aipHostPort: 'http://aigi-drvjbss001:8080/',
  billingPortalApiHost: 'https://insured.archinsurance.com/api/',
  chaseOrigin: 'https://www.chasepaymentechhostedpay.com',
  chaseCallBackUrl: 'https://insured.archinsurance.com/',
  aipHostPort1: 'https://portal.archinsurance.com/aip-base-api',
  aipHostPort2: 'https://portal.archinsurance.com/api-menu',
  aipHostPortMenu: 'https://portal.archinsurance.com/api-menu/',
  topmenuEndPoint: 'aip-authorization-ms/authorize/menuset/menu',
  aipHostPortAdmin: 'https://portal.archinsurance.com',
  appUrl: 'https://portal.archinsurance.com/',
  aipAdminRedirectUrl: 'https://portal.archinsurance.com',

  // bookOfBusinessEndPoint: 'aipdashboardms/getYtdMtdCurrentBookOfBusiness',
  // currentBoBForFromToDateEndPoint: 'aipdashboardms/getCurrentBoBForFromToDate',
  // actionItemsEndPoint: 'aipdashboardms/getActionItems',
  // permissionEndPoint: 'aipauth0api/getRolesAndPortals',
  // bopQuoteEndPoint: 'api/quote',
  // paymentScheduleEndpoint: 'billing-portal-api/paymentSchedule',
  // initializePaymentEndPoint: 'billing-portal-api/initializePayment',
  // paymentQueryEndpoint: 'billing-portal-api/paymentQuery',
  // updateCardEndPoint: 'billing-portal-api/updateCard/',
  // getPolicyEndPoint: 'billing-portal-api/getPolicy',
  // bindEndpoint: 'billing-portal-api/bind',
  // policiesEndpoint: 'billing-portal-api/policies/',
  // bondPostPayment: 'billing-portal-api/bond/postPayment/',
  // getBondsByEmail: 'billing-portal-api/bonds/',
  // billingInquiry: 'billing-portal-api/billingInquiry/',
  // printPolicyEndpoint: 'billing-portal-api/print',
  // topmenuEndPoint: 'aip-authorization-ms/authorize/menuset/menu',
  serviceEndPointUserDetail: '/aip-base-api/getUserdetails/',
  // getPortals: '/aip-admin-api/getPortals/',
  getSignature: '/aip-admin-api/getUserSignature',
  updateSignature: '/aip-admin-api/saveUserSignature',
  serviceEndPortals: '/aip-admin-api/aip-admin-api/getPortals',
  // serviceEndPointaddUser: '/aip-admin-api/aip-admin-api/addUser',
  serviceEndPointGetRolesAgenciesAndProducts: '/aip-admin-api/aip-admin-api/getRolesAgenciesAndProducts',
  // adminPortalEndpoint: '/admin',
  // serviceEndPointsContentServer: 'audit/resourcePath?appName=',
  // userClaims: 'aipsecurityapi/loginUnregisteredUserClaims',

  internalUserFlag: true,
  externalUserFlag: true,
  showDebug: false,
  AIP_USER_ROLE: 'AIGI-ROLE-DIGAIP-USER',
  AIP_ARCH_ROLE: 'AIGI-ROLE-DIGAIP-ARCH',
  AIP_AGENCY_ROLE: 'AIGI-ROLE-DIGAIP-AGENCY',
  AIP_ADMIN_ROLE: 'AIGI-ROLE-DIGAIP-ADMIN',
  CAS_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-CAS-ARCH',
  CAS_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-CAS-AGENCY',
  CAS_ENV_ROLE: 'AIGI-ROLE-OBCAS-USER',
  DIGDA_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-DIGDA-ARCH',
  DIGDA_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-DIGDA-AGENCY',
  DIGDA_ENV_ROLE: 'AIGI-ROLE-DIGDA-USER',
  FPL_FUNCTIONAL_ROLE_ARCH: 'AIGI-ROLE-OBFPL-ARCH',
  FPL_FUNCTIONAL_ROLE_AGENCY: 'AIGI-ROLE-OBFPL-AGENCY',
  FPL_ENV_ROLE: 'AIGI-ROLE-OBFPL-USER',
  CLAIMS_FUNCTIONAL_ROLE_USER: 'AIGI-ROLE-CLAIMS-USER',
  CLAIMS_FUNCTIONAL_ROLE_ADMIN: 'AIGI-ROLE-CLAIMS-ADMIN',
  CLAIMS_ENV_ROLE: 'AIGI-ROLE-CLAIMS-USER',
  SURETY_FUNCTIONAL_ROLE_UW: 'AIGI-ROLE-SURETY-UNDERWRITER',
  SURETY_FUNCTIONAL_ROLE_AGENT: 'AIGI-ROLE-SURETY-AGENT',
  SURETY_ENV_ROLE: 'AIGI-ROLE-SURETY-USER',
  SYNC_WITH_PL_PORTAL: 'pl-portal-prod',
  SYNC_WITH_UW_PORTAL: 'uw-portal-prod',
  SYNC_WITH_AIP_BASE_PORTAL: 'aip-base-portal-prod',
  SYNC_WITH_PAYMENT_PORTAL: 'payment-portal-prod',
  ARCH: 'ARCH',
  AGENCY: 'AGENCY',
  AGENCY_ROLE: 'AIGI-ROLE-AGENCY-UNDERWRITER',
  ARCH_ROLE: 'AIGI-ROLE-ARCH-UNDERWRITER',
  internal: 'ARCH',
  external: 'AGENCY',
  internalUser: 'Internal',
  externalUser: 'External',
  INTERNAL_EMAIL_DOMAIN: [
    'archinsurance.com',
    'archgroup.com',
    'archcapservices.com',
    'archgso.com',
    'corp.archcapservices.com',
  ],
  SKIP_PORTALS_INTERNAL_USER: ['AIGI-BasePortal'],
  AUTO_PORTALS_INTERNAL_USER: [{ 'AIGI-BasePortal': 'AIGI-ROLE-DIGAIP-ARCH-UNDERWRITER' }],
  AUTO_PORTALS_EXTERNAL_USER: [{ 'AIGI-BasePortal': 'AIGI-ROLE-DIGAIP-AGENCY-UNDERWRITER' }],
};
