import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { constants } from '@app/@shared/constants';
import { MaintenanceSchedule, MaintenanceService } from '@archinsurance-da/arch-portal-tools';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  constructor(private maintenanceService: MaintenanceService, private router: Router) {}

  ngOnInit() {
    const startMDate = new Date(constants.maintenanceStartDate);
    const endMDate = new Date(constants.maintenanceEndDate);

    startMDate.toDateString();

    endMDate.toDateString();

    let shedules: MaintenanceSchedule[] = [
      {
        // title: 'This site is temporaily unavailable for maintenance from',
        startDTS: startMDate,
        endDTS: endMDate,
        // description: 'We apologize for the inconvenience. Thank you.',
        warnMinutesBefore: constants.warnMinutesBefore,
      },
    ];

    //Change startDTS and endDTS to show currentMain
    const currentMaintenances = this.maintenanceService.isScheduledMaintenance(shedules, true); //IF second param is false means do not show upcoming
    const currentMaint = currentMaintenances[0];
    if (currentMaint) {
      this.router.navigate(['/maintenance'], { skipLocationChange: true });
    }
  }
}
